import { Application } from "@evento/application/utils";
import { Layout as CalendarLayout } from "@evento/calendar";
import { Layout, HomepageLayout } from "@evento/ucme-se-prezit-components/layouts";

import calendarPages from "@evento/calendar/pages";
import reservationPages from "@evento/reservation/pages";
import defaultPages from "@evento/wireframe/pages";
import currentPages from "@evento/ucme-se-prezit-components/pages";

import Provider from "./Provider.svelte";
import "@base/styles";

const pages = {
    ...calendarPages,
    ...reservationPages,
    ...defaultPages,
    ...currentPages,
};

const app = new Application()
    .setPages({ default: pages })
    .setLayouts({
        default: [Provider, Layout],
        homepage: [Provider, HomepageLayout],
        calendar: [Provider, CalendarLayout],
    })
    .setLayoutResolver(async (name, layouts, resolve) => {
        if (name.startsWith("Calendar/")) {
            return await resolve(layouts.calendar);
        }

        if (name === "Homepage" || name.startsWith("Campaign/")) {
            return await resolve(layouts.homepage);
        }

        return await resolve(layouts.default);
    })
    .setColor("#e6b700")
    .setDebug(import.meta.env.VITE_DEBUG);

app.run();
